/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var __ = wp.i18n.__;
var _x = wp.i18n._x;
var _n = wp.i18n._n;
var _nx = wp.i18n._nx;
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function init() {
        // detects if user is using the keyboard to navigate and if so, adds a class to body
        function handleFirstTab(e) {
          if (e.keyCode === 9 || e.keyCode === 32 || e.keyCode === 13 || e.keyCode === 37 || e.keyCode === 39) {
            // the "I am a keyboard user" key
            document.body.classList.add("user-is-tabbing");
            window.removeEventListener("keydown", handleFirstTab);
          }
        }
        window.addEventListener("keydown", handleFirstTab);

        //credits..
        // console.log(
        //   '%c  \n' +
        //   '%cVortex Solution \n' +
        //   'https://www.vortexsolution.com',
        //   'font-size:30px; background: #fff url(https://www.vortexsolution.com/wp-content/themes/vortex-solution/assets/favicon/favicon-32x32.png) no-repeat; color:#fff;',
        //   'background: #fff; color:#00a6e9;font-weight:bold;'
        // );

        document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
          anchor.addEventListener("click", function (e) {
            e.preventDefault();
            var anchorHref = this.getAttribute("href");
            if (anchorHref !== "#") {
              var anchoredItem = document.querySelector(anchorHref);
              if (anchoredItem) {
                var offsetTop = anchoredItem.offsetTop;
                scroll({
                  top: offsetTop,
                  behavior: "smooth"
                });
              }
            }
          });
        });

        // Init wow.js for animations all over the site
        new WOW().init();
      },
      finalize: function finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    home: {
      init: function init() {
        // JavaScript to be fired on the home page
      },
      finalize: function finalize() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function init() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function loadEvents() {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.