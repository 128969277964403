var _scrollToTop = function scrollToTop() {
  // Let's set a variable for the number of pixels we are from the top of the document.
  var c = document.documentElement.scrollTop || document.body.scrollTop;

  // If that number is greater than 0, we'll scroll back to 0, or the top of the document.
  // We'll also animate that scroll with requestAnimationFrame:
  // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
  if (c > 0) {
    window.requestAnimationFrame(_scrollToTop);
    // ScrollTo takes an x and a y coordinate.
    // Increase the '10' value to get a smoother/slower scroll!
    window.scrollTo(0, c - c / 10);
  }
};
document.addEventListener("click", function (e) {
  if (e.target.matches('#back-to-top')) {
    _scrollToTop();
  }
});